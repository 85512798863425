import type { HeadlineResponse } from '@on3/api';
import { On3Icon5GoatsIconFull } from '@on3/icons';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { CoverList } from 'components/Headlines/ArticleCover/CoverList';
import { FeedList } from 'components/Headlines/ArticleFeed/FeedList';
import { FeedListAlternate } from 'components/Headlines/ArticleFeedAlternate/FeedListAlternate';
import { SEOHomeText } from 'components/Home/SEOHomeText';
import { HomeLayout } from 'components/Layouts/HomeLayout';

import styles from './ProHome.module.scss';

const nflViewAll = [
  {
    url: 'pro/category/nfl/news/',
    text: 'View All NFL',
    title: 'NFL News',
  },
];
const nflDraftViewAll = [
  {
    url: 'pro/category/nfl-draft/news/',
    text: 'View All NFL Draft',
    title: 'NFL Draft News',
  },
];
const nascarViewAll = [
  {
    url: 'pro/category/nascar/news/',
    text: 'View All NASCAR',
    title: 'NASCAR News',
  },
];
const proViewAll = [
  {
    url: 'pro/news/',
    text: 'View All Pro',
    title: 'Pro News',
  },
];

export interface ProHomePageProps {
  latestArticles: HeadlineResponse[] | null;
  nascarArticles: HeadlineResponse[] | null;
  nflArticles: HeadlineResponse[] | null;
  nflDraftArticles: HeadlineResponse[] | null;
}

export const ProHome = ({
  latestArticles,
  nascarArticles,
  nflArticles,
  nflDraftArticles,
}: ProHomePageProps) => {
  const coverArticles = latestArticles?.slice(0, 3);
  const { currentSite } = useSite();
  const siteColor = currentSite?.primaryColor || '#fe3b1f';

  return (
    <>
      {!!coverArticles?.length && (
        <CoverList
          backgroundColor={siteColor}
          categoryColor={'#6f747b'}
          list={coverArticles}
        />
      )}
      <SEOHomeText>
        <On3Icon5GoatsIconFull className={styles.metaIcon} />
        <h1>
          Pro sports media, just enough culture and the juice from the immortal
          5 GOATs. Powered by On3.
        </h1>
      </SEOHomeText>
      <HomeLayout>
        <section className={styles.feed}>
          <div className={styles.inner}>
            {!latestArticles?.length || (
              <FeedList
                list={latestArticles.slice(3, 15)}
                section="Pro News"
                viewAllLinks={proViewAll}
              />
            )}
            <MobileMPU id={'mobile_mpu_top'} incontent />
          </div>
        </section>
        <aside className={styles.sidebarright}>
          <div className={styles.inner}>
            <MPU id={'mpu_top'} />
            <FeedListAlternate
              list={nascarArticles?.slice(0, 5)}
              section="NASCAR News"
              viewAllLinks={nascarViewAll}
            />
            <MPU id={'mpu_middle'} />
            <MobileMPU id={'mobile_mpu_middle'} incontent />
          </div>
        </aside>
        <aside className={styles.sidebarleft}>
          <div className={styles.inner}>
            <FeedListAlternate
              list={nflDraftArticles?.slice(0, 5)}
              section="NFL Draft News"
              viewAllLinks={nflDraftViewAll}
            />
            <FeedListAlternate
              list={nflArticles?.slice(0, 5)}
              section="NFL News"
              viewAllLinks={nflViewAll}
            />
          </div>
        </aside>
      </HomeLayout>
    </>
  );
};
